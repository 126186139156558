<template>
    <fade-up-animation :element="() => $refs.section" :y-from="15">
        <nav
            class="secondary-nav-bar"
            aria-label="Secondary navigation"
            ref="section"
        >
            <ul>
                <li
                    v-for="item in menuItems"
                    :key="item.name"
                >
                    <b-button
                        :to="item.to"
                        variant="primary"
                        size="sm"
                        class="nav-item"
                        v-test:navBtn
                    >
                        {{ item.name }}
                    </b-button>
                </li>
            </ul>
        </nav>
    </fade-up-animation>
</template>

<script>
    export default {
        name: 'secondary-nav-bar',

        props: {
            menuItems: {
                type: Array,
                default: () => ([]),
            },
        },
    };
</script>

<style lang="scss" scoped>
    .secondary-nav-bar {
        padding: 16px 20px;
        background-color: secondary-palette(7);
    }

    .nav-item.btn {
        padding-left: 2px;
        padding-right: 2px;
        min-width: unset;
        width: 100%;
        font-size: rem(14px);

        @include media-breakpoint-up(md) {
            padding: $btn-padding-y $btn-padding-x;
            width: 198px;
            font-size: rem(16px);
        }
    }

    ul {
        margin: 0 -5px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        @include media-breakpoint-up(md) {
            margin: 0 -10px;
        }
    }

    li {
        padding: 0 5px;
        flex: 1 1 0;

        @include media-breakpoint-up(md) {
            padding: 0 12px;
            flex: 0 1 auto;
        }
    }
</style>
